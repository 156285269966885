import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

import Layout from '~/components/layout'
import SEOEn from '~/components/seoEn'
import Input from '~/components/input'
import Textarea from '~/components/textarea'
import Button from '~/components/button'

const apiUrl = process.env.GATSBY_STRAPI_URL || 'http://localhost:1337'

const ContactPage = ({ data: { contactFr, contactEn } }) => {
  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)

  const resetFileInput = e => {
    e.preventDefault()
    document.querySelector('input[type="file"]').value = ''
    setFile(null)
  }
  const {
    form: { title },
    block,
  } = contactEn.nodes[0]
  const {
    form: { background },
  } = contactFr.nodes[0]
  const seo = {
    title: 'Contact us',
    metaDescription: 'Contact our different auction rooms for any question.',
  }

  const tenMo = 10500000

  const sendMail = e => {
    e.preventDefault()
    const name = e.target.name.value
    const email = e.target.email.value
    const phone = e.target.phone.value
    const message = e.target.message.value

    const file = document.querySelector('input[type="file"]').files[0]

    const formData = new FormData()
    formData.append('picture', file)
    formData.append('name', JSON.stringify(name))
    formData.append('email', JSON.stringify(email))
    formData.append('phone', JSON.stringify(phone))
    formData.append('message', JSON.stringify(message))

    fetch(`${apiUrl}/contact`, {
      method: 'POST',
      body: formData,
    })
      .then(res => {
        if (res.status == 200) {
          setError(null)
          e.target.name.value = ''
          e.target.email.value = ''
          e.target.phone.value = ''
          e.target.message.value = ''
          document.querySelector('input[type="file"]').value = ''
          alert('Your message has been sent!')
        }
      })
      .catch(err => {
        console.log(err.message)
        setError('Server error')
      })
  }

  return (
    <Layout isEn>
      <SEOEn seo={seo} />
      <div
        className="bg-no-repeat bg-cover"
        style={{ backgroundImage: `url(${background.url})` }}
      >
        <div className="grid grid-cols-12 container">
          <form
            className="col-span-full tablet:col-start-2 tablet:col-end-12 bg-gray-100 mb-10 push-content px-3"
            onSubmit={e => sendMail(e)}
          >
            <div className="text-center mt-2 text-lg">{title}</div>
            <Input placeholder="Your name" name="name" />
            <Input placeholder="Your e-mail" name="email" />
            <Input placeholder="Your phone number" name="phone" />
            <Textarea placeholder="Your message" name="message" />
            <div className="mt-4 mb-6">
              <label className="block mb-1">
                Send a photo (optional - 10 Mo max).
              </label>
              <input
                accept="image/*"
                type="file"
                name="picture"
                onChange={e => setFile(e.target.files[0])}
              />
              {file && file.size > tenMo && (
                <div className="text-red mt-1">
                  The file must not exceed 10 Mo.{' '}
                  <a
                    href="#"
                    className="text-red underline"
                    onClick={e => {
                      resetFileInput(e)
                    }}
                  >
                    Remove my photo.
                  </a>
                </div>
              )}
            </div>
            <div className="mb-4">
              No personal data is kept by our site via this form.
            </div>
            <Button
              isDisabled={file && file.size > tenMo}
              label="Send"
              type="submit"
            />
            {error !== null ? (
              <div className="text-red text-center mb-2">{error}</div>
            ) : null}
          </form>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="grid grid-cols-12 container py-5">
          <div className="col-start-1 col-end-13">
            <div className="font-bold p-2 w-fit px-2 text-xl">
              {block.title}
            </div>
            <ReactMarkdown className="px-2 pt-4 pb-8 border-t">
              {block.content}
            </ReactMarkdown>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query contactQueryEn {
    contactEn: allStrapiContact(filter: { locale: { eq: "en" } }) {
      nodes {
        form {
          background {
            url
          }
          title
        }
        block {
          content
          title
        }
      }
    }
    contactFr: allStrapiContact(filter: { locale: { eq: "fr-FR" } }) {
      nodes {
        form {
          background {
            url
          }
          title
        }
        block {
          content
          title
        }
      }
    }
  }
`

export default ContactPage
