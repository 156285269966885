import React from 'react'

const Textarea = ({ placeholder, name, isRequired = false }) => {
  return (
    <textarea
      required={isRequired}
      className="block w-full px-2 py-1 my-2 rounded h-10 border border-gray-400 placeholder-gray-500"
      placeholder={placeholder}
      name={name}
    />
  )
}

export default Textarea
